* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "montseratt", sans-serif;
}

/* Base styles for mobile devices */
main {
  min-height: 100vh;
  background-repeat: no-repeat;
  padding: 25px;
  background: url(./images/weather\ .jpg) no-repeat center center/cover;
}

h1 {
  margin-left: 20px; /* Margin for mobile */
  color: black;
}

.text {
  margin-left: 20px; /* Margin for mobile */
  width: 90%; /* Adjust width for mobile */
}

.location {
  color: #000000;
  font-size: 40px; /* Font size for mobile */
  font-weight: 500;
  padding-top: 10px;
  margin-bottom: 30px;
  text-align: center;
  background-size: 10px;
}

.description {
  font-weight: bold;
}

/* Media query for larger screens (tablets and laptops) */
@media only screen and (min-width: 501px) {
  h1 {
    margin-left: 40%; /* Margin for larger screens */
  }
  h3 {
    margin-left: 0%;
  }

  .text {
    margin-left: 30%; /* Margin for larger screens */
    width: 40%; /* Width for larger screens */
  }
  weather {
    background: none;
  }
  .location {
    font-size: 62px; /* Font size for larger screens */
  }
}
